@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color:  #1f2937;
    /*background-color: aliceblue;*/
    /* background-image: linear-gradient(131deg, #ffff 30%, #e2f1ff 100%); */
    font-family: "Poppins";
    font-weight: 400;
    overflow-x: hidden;;
  }

  :root {
    touch-action: pan-x pan-y;
    height: 100% 
  }

blockquote {
    border-left: #e1e6ec solid 2px;
}

.tc-search-map-container {
    max-height: 40rem;
    min-height: 40rem;
}

.tc-search-map-tc-list {
    max-height: 40rem;
    overflow-y: auto;
}

.pac-container {
    border-radius: 0.5rem;
}

.pac-container .pac-item {
    padding: .1rem .5rem;
}

.pac-logo::after {
    display: none;
}

.tc-map {
    min-height: 15rem;
}
